export default [
  // {
  //   path: '/dashboard/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  // },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  // },

  // {
  //   path: '/dashboard/products-purchases',
  //   name: 'products-purchase',
  //   component: () => import('@/views/dashboard/ecommerce/ProductsPurchase.vue'),
  // },
  // {
  //   path: '/dashboard/promotions',
  //   name: 'ecommerce-promotions',
  //   component: () => import('@/views/dashboard/ecommerce/Promotions.vue'),
  // },
 

  // Website
  // {
  //   path: '/header',
  //   name: 'header',
  //   component: () => import('@/views/Website/Header/Header.vue'),
  //   meta: {
  //     layout: 'full',
  //     pageTitle: 'Layout Blank',
  //   },
  // },
  // {
  //   path: '/footer',
  //   name: 'Footer',
  //   component: () => import('@/views/Website/footer/Footer.vue'),
  //   meta: {
  //     layout: 'full',
  //     pageTitle: 'Layout Blank',
  //   },
  // },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/Website/Index.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Home - BRIKS',
    },
  },
  {
    path: '/Vue-Carousel',
    name: 'VueCarousel',
    component: () => import('@/views/Website/VueCarousel.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Layout Blank',
    },
  },
 
 
  {
    path: '/create',
    name: 'create-images',
    component: () => import('@/views/Website/imageListing/imageListing.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Create - BRIKS',
    },
  },
  {
    path: '/create/images',
    name: 'create-images',
    component: () => import('@/views/Website/imageListing/imageListing.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Create Images - BRIKS',
    },
  },
  
  // Create Memes Route
  {
    path: '/create/memes',
    name: 'create-memes',
    component: () => import('@/views/Website/imageListing/imageListing.vue'), 
    meta: {
      layout: 'full',
      pageTitle: 'Create Memes - BRIKS',
    },
  },
  {
    path: '/create/quotes',
    name: 'create-quotes',
    component: () => import('@/views/Website/imageListing/imageListing.vue'), 
    meta: {
      layout: 'full',
      pageTitle: 'Create Quotes - BRIKS',
    },
  },
  {
    path: '/image',
    name: 'ImagePage',
    component: () => import('@/views/Website/imageListing/imagePage.vue'),
    props: (route) => ({ imageUrl: route.query.url }),
    meta: {
      layout: 'full',
      pageTitle: 'Image - BRIKS',
    },
  },
  
  {
    path: '/explore',
    name: 'explore-images',
    component: () => import('@/views/Website/imageListing/featuredListing.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Featured - BRIKS',
    },
  },
  {
    path: '/feed',
    name: 'feed-images',
    component: () => import('@/views/Website/imageListing/feedPage.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Feed - BRIKS',
    },
  },
  // {
  //   path: '/product-details',
  //   name: 'product-details',
  //   component: () => import('@/views/Website/productdetails/ProductDetails.vue'),
  //   meta: {
  //     contentClass: 'ecommerce-application',
  //     layout: 'full',
  //      pageTitle: 'Product Details',
  //   },
  // },

  {
    path: '/plans',
    name: 'Plans',
    component: () => import('@/views/Website/Pricing.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Plans - BRIKS',
    },
  },
  {
    path: '/membership-checkout',
    name: 'membership-checkout',
    component: () => import('@/views/Website/MembershipCheckout.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      layout: 'full',
    },
  },


 


  // {
  //   path: '/product-details/:slug',
  //   name: 'apps-e-commerce-product-details',
  //   component: () => import('@/views/Website/productdetails/ProductDetails.vue'),
  //   meta: {
  //     pageTitle: 'Product Details',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //       {
  //         text: 'Product Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  
  {
    path: '/checkout',
    name: 'e-checkout',
    component: () => import('@/views/Website/e-commerce-checkout/checkOut.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      layout: 'full',
    },
  },


  // Profile pages
  {
    path: '/user-profile',
    name: 'profile-view',
    component: () => import('@/views/Website/UsersDashboard/ProfileView.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Profile - BRIKS',
    },
  },

  // {
  //   path: '/user-profile',
  //   name: 'user-profile',
  //   component: () => import('@/views/Website/UsersDashboard/usersprofile/ProfileDashboard.vue'),
  //   meta: {
  //     layout: 'full',
  //     pageTitle: 'Layout Blank',
  //   },
  // },

  // {
  //   path: '/user-profile-edit',
  //   name: 'user-profile-edit',
  //   component: () => import('@/views/Website/UsersDashboard/usersprofile/users-profile-edit/UsersProfileEdit.vue'),
  //   meta: {
  //     layout: 'full',
  //     pageTitle: 'Layout Blank',
  //   },
  // },

  // {
  //   path: '/memberships',
  //   name: 'memberships',
  //   component: () => import('@/views/Website/Pricing.vue'),
  //   meta: {
  //     layout: 'full',
  //     pageTitle: 'Layout Blank',
  //   },
  // },

  {
    path: '/collections',
    name: 'My Collections',
    component: () => import('@/views/Website/imageListing/myCollections.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'My Collection - BRIKS',
    },
  },
 
  {
    path: '/billings',
    name: 'billings',
    component: () => import('@/views/Website/UsersDashboard/billings/MyBilling.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Layout Blank',
    },
  },


  // {
  //   path: '/onboarding',
  //   name: 'form-wizard',
  //   component: () => import('@/views/Website/onboarding/FormWizard.vue'),
  //   meta: {
  //     layout: 'full',
  //     pageTitle: 'Form Wizard',
  //     breadcrumb: [
  //       {
  //         text: 'Forms',
  //       },
  //       {
  //         text: 'Form Wizard',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },

  {
    path: '/thank-you/:id/:slug', 
    name: 'thank-you',
    component: () => import('@/views/Website/thank-you/ThankYou.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Layout Blank',
    },
  },

  {
    path: '/account-deletion', 
    name: 'account-deletion',
    component: () => import('@/views/Website/account-deletion/AccountDeletion.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Layout Blank',
    },
  },
  {
    path: '/privacy-policy', 
    name: 'privacy-policy',
    component: () => import('@/views/Website/privacypolicy.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Privacy Policy',
    },
  },
  {
    path: '/termsandconditions', 
    name: 'privacy-policy',
    component: () => import('@/views/Website/TermsAndConditions.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Terms And Conditions',
    },
  },


  
]
